import app from '../feathers';
import logSentry from './logSentry';

const getPincode = async (releaseAddresses, organizationName) => {

  const pincodeResults = await window.walletApi.getPincodeForReleases( releaseAddresses, { from: organizationName } )

  if (pincodeResults) {
    const pincodeSuccesses = pincodeResults.filter(res => res.pincode).map(res => ({releaseAddress: res.address}))

    if (pincodeSuccesses.length) {
      try {
        await app.service('pincode-log').create(pincodeSuccesses);
      } catch (e) {
        logSentry(e);
      }
    }

    return pincodeResults;
  } else {
    throw new Error("getpincode.error.general")
  }
};

const filterSelectedReleases = (selectedReleaseAddresses, release) => {
  return selectedReleaseAddresses.length > 0 ? selectedReleaseAddresses.includes(release.releaseAddress || release.address) : true
}

// SCRDEV-1973: no pins for blocked containers
// SCRDEV-2844: no pins for deleted & gateout containers
const filterUnavailableReleases = (release) => {
  return !["blocked", "deleted", "gateout"].includes(release.releaseStatus.toLowerCase());
}

const filterPcsReleases = (release) => {
  return !release.sentToPcs;
}

const filterPinAllowedLocations = (assignLocationsCheckResult, release) => {
  const alr = assignLocationsCheckResult.data.find(r => r.address === release.releaseAddress || r.address === release.address);
  return alr ? alr.pin_allowed : true;
}

const filterAssignLocationsReleases = (assignLocationsCheckResult, release, userCanFetchPincode) => {
  const alr = assignLocationsCheckResult.data.find(r => r.address === release.releaseAddress || r.address === release.address);
  if (alr) {

    let alrTransferStatusCheck = true;
    let alrIsSecurePickupCheck = true;
    
    if (alr.required_transfer_status) {
      alrTransferStatusCheck = alr.required_transfer_status.toLowerCase() === release.transferStatus.toLowerCase();
    }

    if (alr.is_secure_pickup && !userCanFetchPincode) {
      alrIsSecurePickupCheck = false;
    }
    return alrTransferStatusCheck && alrIsSecurePickupCheck;
  } 
  
  return true;
}

export { 
  getPincode,
  filterSelectedReleases,
  filterUnavailableReleases,
  filterPinAllowedLocations,
  filterPcsReleases,
  filterAssignLocationsReleases,
}
