import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme({
  palette: {
    primary: {
      light: '#fcf4e3',
      main: '#cda24d',
      dark: '#c2823c',
    },
    secondary: {
      light: grey[300],
      main: grey[500],
      dark: grey[700],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    // MuiIconButton: {
    //   root: {
    //     padding: "0.2em",
    //   },
    // },
    MuiDropzoneArea: {
      root: {
        minHeight: '160px',
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1em',
      }
    },
    MuiButtonBase: {
      root: {
        padding: '0.25em',
      },
    },
    MUIDataTable: {
      root: {
        marginBottom: '2em',
      },
      responsiveScroll: {
        overflowX: 'hidden',
      },
      responsiveBase: {
        position: 'inherit !important',
      },
    },
    MuiTableCell: {
      root: {
        // padding: '8px 8px 8px 1.5em'
        padding: '4px'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        color: '#cda24d'
      },
      data: {
        fontSize: '12px'
      }
    },
    MUIDataTableSelectCell: {
      root: {
        paddingLeft: '1em'
      },
      fixedLeft: {
        backgroundColor: '#fff'
      }
    },
    MuiGridList: {
      root: {
        minWidth: '200px'
      }
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'bottom',
        marginRight: '2px',
      },
    },
  },
});

function TMWithRoot(Component) {
  function withRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return withRoot;
}

export default TMWithRoot;
