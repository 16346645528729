import axios from 'axios';
import { getAccessToken } from './util/token';

class Http {
  static async get(url, options) {
    return await this._axiosRequest({ method: 'get', url, options });
  }

  static async post(url, data, options) {
    return await this._axiosRequest({ method: 'post', url, data, options });
  }
  static async patch(url, data, options) {
    return await this._axiosRequest({ method: 'patch', url, data, options });
  }

  static async update(url, data, options) {
    return await this._axiosRequest({ method: 'put', url, data, options });
  }

  static async _axiosRequest({ method, url, data, options = {} }) {
    options.headers = {
      Authorization: `Bearer ${options.hasOwnProperty("jwt") ? options.jwt : await getAccessToken()}`,
    };
    options.baseURL = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = data
        ? await axios[method](url, data, options)
        : await axios[method](url, options);

      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.error || error.response?.data?.message || error.message
      );
    }
  }
}

export { Http };

export default axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
