import React /*, { useReducer }*/ from 'react'
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import useIntl from '../hooks/useIntl'
import { Button } from '@material-ui/core'
const { xorBy } = require('lodash');

const PcsActionDialog = ({ show, pcsOpType='transferToPcs', onPcsAction, onCancel, selectedReleaseAddresses, containersForPcs }) => {

	const { translate } = useIntl()

	const title = translate(`pcs.${pcsOpType}.dialog.title`);
	// filter out releases not associated with a PCS
	const noPcsAvailable = containersForPcs.filter(c => !c.ediPcs);
	// filter out releases that don't have the right PCS status, then filter out any doubles
	const sendStatusConflict = containersForPcs
		.filter(c => pcsOpType === 'transferToPcs' ? c.sentToPcs === true : c.sentToPcs !== true)
		.filter(c1 => noPcsAvailable.every(c2 => c1.address !== c2.address));
	// filter out releases that were transferred to another party, then filter out any doubles
	const alreadyTransferred = containersForPcs
		.filter(c => c.transferStatus === 'transferred')
		.filter(c1 => noPcsAvailable.every(c2 => c1.address !== c2.address))
		.filter(c1 => sendStatusConflict.every(c2 => c1.address !== c2.address));

	// The eligible releases are those releases that do not occur in one of the arrays above
  const eligibleContainers = xorBy(containersForPcs, noPcsAvailable, sendStatusConflict, alreadyTransferred, 'address');

	const actions = (
		<div style={controlsStyle}>
			<Button variant="contained" color="secondary" onClick={() => onCancel()}>{translate('general.cancel')}</Button>
			<Button variant="contained" disabled={eligibleContainers.length === 0} color="primary" onClick={() => onPcsAction(pcsOpType, eligibleContainers)}>
				{translate(`blActions.${pcsOpType}`)}
			</Button>
		</div>
	)

	return (
		<Dialog open={show} onClose={onCancel}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{ 
					eligibleContainers.length > 0 &&
					<div>
						{ translate(`pcs.${pcsOpType}.dialog.confirm`, { numberOfReleases: eligibleContainers.length || 0 }) }
						<ul>
						{
							eligibleContainers.map((c, idx) => <li key={idx}>{c.containerNumber} {pcsOpType === 'transferToPcs' ? 'to' : 'from'} {c.ediPcs}</li>)
						}
						</ul>
					</div>
				}
				{
					noPcsAvailable.length > 0 &&
					<div>
						{ translate(`pcs.${pcsOpType}.dialog.noPcsAvailable`) }
						<ul>
						{
							noPcsAvailable.map((c, idx) => <li key={idx}>{c.containerNumber}</li>)
						}
						</ul>
					</div>
				}
				{
					sendStatusConflict.length > 0 &&
					<div>
						{ translate(`pcs.${pcsOpType}.dialog.sendStatusConflict`) }
						<ul>
						{
							sendStatusConflict.map((c, idx) => <li key={idx}>{c.containerNumber}</li>)
						}
						</ul>
					</div>
				}
				{
					alreadyTransferred.length > 0 &&
					<div>
						{ translate(`pcs.${pcsOpType}.dialog.alreadyTransferred`) }
						<ul>
						{
							alreadyTransferred.map((c, idx) => <li key={idx}>{c.containerNumber}</li>)
						}
						</ul>
					</div>
				}
			</DialogContent>
			<DialogActions>{actions}</DialogActions>
		</Dialog>
	)
}

export default PcsActionDialog

const controlsStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	padding: 10,
}
